@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.data-table {
    thead {
        tr {
            th:nth-child(6) {
                width: 100px;
            }
            @include breakpoint("tablet") {
                th {
                    &:nth-child(2),
                    &:nth-child(5) {
                        display: none;
                    }
                }
            }

            @include breakpoint("phone") {
                th {
                    &:nth-child(2),
                    &:nth-child(5) {
                        display: none;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            @include breakpoint("tablet") {
                td {
                    &:nth-child(2),
                    &:nth-child(5) {
                        display: none;
                    }
                }
            }

            @include breakpoint("phone") {
                td {
                    &:nth-child(2),
                    &:nth-child(5) {
                        display: none;
                    }
                    text-align: center;
                }
            }
        }
    }
}
