@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.item-title {
    font-size: 1rem;
    font-weight: 600;
}

.sticky-button-container {
    position: relative;
    display: unset;
    .sticky-button {
        position: sticky;
        top: 60px;
        z-index: 1;
    }
}
