@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container.show {
    transform: translateX(0);
}
.container {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    padding-top: 80px;
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.57, 0.12, 0.45, 0.92);
    width: 170px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(255, 255, 255, 0.4);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background: $company-fire-light-more;
    }
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    @include breakpoint("phone-tablet") {
        padding-top: 40px;
        width: 100%;
        z-index: 4;
    }
    .nav-title {
        margin: 0 5%;
        font-size: 20px;
        font-weight: 300;
        color: #333;
    }
    ul {
        display: flex;
        flex-flow: wrap;
    }
    .list-container {
        width: 50%;
        @include breakpoint("phone-tablet") {
            width: 25%;
        }
        .link.active {
            span {
                color: $active-color;
                font-weight: 500;
            }
        }
        .link {
            padding: 14px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .circle {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 5px;
                i {
                    font-size: 0.8rem;
                    color: white;
                }
                &.jobs {
                    background: $job-menu-background;
                }
                &.tasks {
                    background: $task-menu-background;
                }
                &.operation {
                    background: $operation-menu-background;
                }
                &.crm {
                    background: $crm-menu-background;
                }
                &.hr {
                    background: $hr-menu-background;
                }
                &.stock {
                    background: $stock-menu-background;
                }
            }

            span {
                display: block;
                font-size: 0.9rem;
                font-weight: 400;
                color: #07060e;
            }
        }
    }
    .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        button {
            outline: none;
            padding: 0;
            position: relative;
            width: 30px;
            height: 20px;
            background: none;
            border: none;
            display: block;
            span {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.67, 0.34, 0.4, 0.93);
                transition: transform 0.4s cubic-bezier(0.67, 0.34, 0.4, 0.93);
                display: block;
                width: 100%;
                height: 2px;
                background: #333;
                position: absolute;
                &:nth-child(1) {
                    -webkit-transform: translateY(10px) rotate(-45deg);
                    transform: translateY(10px) rotate(-45deg);
                    top: 0;
                }
                &:nth-child(2) {
                    -webkit-transform: translateY(-8px) rotate(45deg);
                    transform: translateY(-8px) rotate(45deg);
                    bottom: 0;
                }
            }
        }
        @include breakpoint("phone-tablet") {
            display: block;
        }
    }

    .logout-container {
        position: absolute;
        right: 10px;
        bottom: 35px;
        @include breakpoint("phone-tablet") {
            position: fixed;
        }
    }
}
