@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.title {
    padding-left: 20px;
}
// .flame {
//     position: absolute;
//     top: -14px;
//     left: 12px;
//     display: inline-block;
//     width: 18px;
//     height: 40px;
//     background-image: url("../../images/flame.png");
//     background-repeat: no-repeat;
//     background-size: 153px 40px;
//     &.is-play {
//         -webkit-animation: play 0.9s steps(9) infinite;
//         animation: play 0.9s steps(9) infinite;
//     }
// }

// @keyframes play {
//     0% {
//         background-position: 0px;
//     }
//     100% {
//         background-position: -153px;
//     }
// }
