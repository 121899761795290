@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container {
    padding-top: 20px;
    .card-container {
        margin-top: 20px;
    }
    .list-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .list {
            width: 85%;
        }
        .button {
            text-align: center;
            width: 10%;
            min-width: 40px;
        }
    }
}
