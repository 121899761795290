@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.new-button {
    z-index: 4;
    position: fixed;
    top: 70px;
    right: 15px;
    .dropdown-toggle {
        margin: 0;
        padding: 0 !important;
        width: 45px;
        height: 45px;
        border-radius: 22px;
        font-size: 0.875rem !important;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        min-height: 36px;
    }
    .drop-down-item {
        padding: 0;
        .link {
            display: block;
            padding: 0.3rem 1.5rem;
            width: 100%;
            height: 100%;
            color: #333;
            font-size: 0.9rem;
        }
    }
}
