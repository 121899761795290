@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.switch-container {
    .switch-title {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 300;
        color: #333;
    }
    margin: 0 5%;
    width: 90%;
    pointer-events: initial;
}
