@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.branch-select {
    width: 100%;
    max-width: 300px;
    position: relative;
    .loading {
        position: absolute;
        top: 4px;
        right: 3px;
    }
}
