@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.file-list-container {
    display: flex;
    gap: 0.5rem;
    // flex-direction: column;
    flex-wrap: wrap;
    // margin: 0 -0.25rem;
    font-size: 0.9rem;
    li {
        &.new-file {
            border: none;
        }
        padding: 2px 6px;
        border: 1px solid #ced4da;
        border-radius: 12px;
        // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    }
}

.file-list {
    display: flex;
    align-items: center;
}

.inline-form-control {
    display: inline-block !important;
    width: auto !important;
    min-width: 160px;
}

.note-label {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: $gray-dark;
}

.text-box {
    display: inline-block;
    min-width: 160px;
    font-weight: 400;
    color: #495057;
    border: 1px solid #ced4da;
    background-color: #e9ecef;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    vertical-align: middle;
    overflow: hidden;
    word-break: break-all;
}

.time-indicator {
    color: $green;
}
.over-due {
    color: $red;
}

.budget-tracker-row-container {
    align-items: center;
    .column-container {
        border-top: 1px solid #dee2e6;
        @include breakpoint("phone-tablet") {
            border: none;
        }
        &:first-child {
            border: none;
        }
    }
}

.reply-item {
    &:first-child {
        margin-top: 0;
    }
    margin-top: 5px;
    padding-left: 60px;
    @include breakpoint("phone") {
        padding-left: 0;
    }
}

.reply-form-container {
    padding-left: 80px;
    @include breakpoint("phone") {
        padding-left: 0;
    }
}

.ellipsis {
    position: relative;
    width: calc(100% - 250px);
    max-width: 2000px;
    @include breakpoint("phone") {
        width: 100%;
    }
    &:before {
        visibility: hidden;
        @include breakpoint("phone-tablet") {
            visibility: initial;
        }
    }
    &:hover {
        .hover-text {
            transition: opacity 0.2s cubic-bezier(0, 0, 0, 0.2), transform 0.2s cubic-bezier(0, 0, 0, 0.2);
            transform: translateY(25px);
            opacity: 1;
            white-space: pre-wrap;
        }
    }
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .hover-text {
        position: absolute;
        top: 0;
        left: 0.3rem;
        right: 0.3rem;
        pointer-events: none;
        text-align: left;
        z-index: 3;
        padding: 10px;
        opacity: 0;
        border: 1px solid $company-fire-dark;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transform-origin: 50% 0;
        transform: translateY(40px);
        overflow: visible;
        white-space: normal;
        height: auto;
        background: white;
    }
}
