@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container {
    width: 100% !important;
    border: 1px solid $normal-line-color !important;
    border-radius: 3px;
    :global {
        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
            background-color: #e6e6e6;
        }
        .react-calendar__tile--active,
        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            background: white;
            color: black;
        }
        .react-calendar__tile--now {
            background-color: $blue;
            color: white;
        }
    }
}
.selected {
    background-color: $teal !important;
    color: white !important;
}

.weekend {
    background-color: #efefef !important;
}
