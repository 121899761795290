@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
@mixin background {
    background-size: 1280px 200px !important;
    width: 100%;
    height: 200px;
    transform: translate3d(0, 0, 0);
}

.container {
    position: relative;
    margin: 0 -15px;
}

.title-container {
    position: absolute;
    top: 70px;
    width: 100%;
    text-align: center;
    &.full-screen {
        top: 55px;
    }
}

.title {
    margin: 0;
    p {
        margin: 0;
        display: inline-block;
        padding: 7px 15px;
        background: rgba(255, 255, 255, 0.9);
        transform: skew(-10deg, 0);
        font-weight: bold;
        color: #f05322;
        font-size: 2.5rem;
    }
}

.sub-title {
    display: inline-block;
    margin-top: -10px;
    padding: 8px 15px;
    background: $company-fire-dark;
    transform: skew(-10deg, 0);
    font-size: 1rem;
    font-weight: 500;
    color: white;
}

.attribution {
    position: fixed;
    right: 10px;
    top: 55px;
    font-size: 0.7rem;
    a {
        color: #333;
        text-decoration: underline;
    }
    &.full-screen {
        top: 10px;
    }
}

.game-canvas {
    canvas {
        display: block;
    }
}

.logo {
    position: absolute;
    left: 20px;
    width: 150px;
}
