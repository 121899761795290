@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.arrow-back {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    width: 36px;
    height: 36px;
    &:focus {
        outline: none;
    }
    .bar {
        width: 20px;
        height: 2px;
        background: $company-fire-dark;
        position: absolute;
        top: calc(50% - 2px);
        left: 9px;
    }
    &::before {
        position: absolute;
        top: calc(50% - 2px);
        left: 20%;
        content: "";
        width: 15px;
        height: 2px;
        background: $company-fire-dark;
        transform: rotate(-40deg);
        transform-origin: left center;
    }
    &::after {
        position: absolute;
        top: calc(50% - 2px);
        left: 20%;
        content: "";
        width: 15px;
        height: 2px;
        background: $company-fire-dark;
        transform: rotate(40deg);
        transform-origin: left center;
    }
}
