@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.title-wrap {
    align-items: center;
    .title {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
    }
}
.schedule-table {
    table {
        width: 100%;
        background: white;
        background-clip: padding-box;
        margin-bottom: 0 !important;
        border-collapse: separate !important;
    }
    thead {
        th {
            text-align: center;
            padding: 0.75rem !important;
            background-color: #eaeaea;
            border-top: 1px solid #9e9e9e !important;
            border-right: 1px solid #9e9e9e !important;
            border-bottom: 1px solid #9e9e9e !important;
            vertical-align: middle !important;
            min-width: 140px;
            .calendar-day {
                display: block;
                font-size: 0.7rem;
                color: rgba(0, 0, 0, 0.6);
            }
            &:first-child {
                border-left: 1px solid #9e9e9e !important;
            }
        }
        .current-week {
            background-color: $company-fire-light-more;
        }
        .today {
            background-color: #e3f2fd;
        }
        .holiday {
            background: #fff79d;
        }
    }
    tbody {
        tr {
            &.even {
                background: rgba(0, 0, 0, 0.05);
                th {
                    background: #f4f4f4;
                }
            }
            th {
                vertical-align: middle;
                padding: 0.75rem !important;
                z-index: 1;
                background: white;
                border-left: 1px solid #9e9e9e !important;
                border-right: 1px solid #9e9e9e !important;
                border-bottom: 1px solid #9e9e9e !important;
                position: sticky;
                left: 0;
                min-width: 150px;
                width: 15%;
                height: 47px;
            }
            td {
                z-index: 0;
                position: relative;
                padding: 0.25rem 0.25rem !important;
                min-width: 150;
                width: 15%;
                border-right: 1px solid #9e9e9e !important;
                border-bottom: 1px solid #9e9e9e !important;

                // &.unassigned {
                //     background: #bbdefb;
                // }
                &.weekend {
                    background: rgba(0, 0, 0, 0.06);
                }
                &.empty {
                    background: #ffd700;
                }
                .today-worker-list {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    li {
                        margin-right: 1rem;
                    }
                }

                .worker-list {
                    padding: 0.8rem;
                    margin-bottom: 0;
                    text-align: left;
                    li {
                        position: relative;
                        padding: 0.1rem;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        font-size: 0.875rem;
                        span {
                            margin-left: 0.2rem;
                            border-radius: 3px;
                            width: 5px;
                            height: 5px;
                        }
                    }
                }
            }
        }
    }
}

.labour-info {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    li {
        margin-left: 0.2rem;
        padding: 0.1rem 0.3rem;
        font-size: 0.65rem;
        font-weight: 400;
        border-radius: 0.3rem;
        color: white;
    }
    @include breakpoint("phone-tablet") {
        display: none;
    }
}
