@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.card {
    .with-btn {
        position: relative;
        padding-right: 120px;
        .fit-btn {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            right: 15px;
            width: 100px;
            height: 38px;
            button {
                margin: 0;
            }
        }
    }

    input[type="date"] {
        word-break: keep-all;
        word-wrap: normal;
        overflow: hidden;
    }

    .button-size {
        padding: 0.3rem 0.7rem !important;
        font-size: 1rem !important;
    }
}

div:global(.recipient-input__input) {
    width: 100%;
    input {
        width: 100% !important;
    }
}
div:global(.recipient-input__value-container) {
    > div:last-child {
        flex: 1;
    }
}
