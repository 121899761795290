@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");

// GLOBAL
body {
    font-family: $font-family !important;
}

textarea {
    resize: none;
}

.container {
    .main {
        background-color: $content-background;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
    }
}

.reload-notif {
    z-index: 2000;
    .reload-btn {
        &:focus {
            outline: none;
        }
        margin: 0 !important;
        padding: 0.5rem !important;
        border: none;
        background-color: transparent !important;
        font-size: 1rem !important;
        color: red !important;
    }
}
