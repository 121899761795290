@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.client-name {
    font-size: 0.8rem;
    color: #333;
}

.status-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100%;
    top: 0;
    right: 0;
    .status {
        padding: 2px 6px;
        width: 26px;
        height: 19px;
        font-size: 0;
        span {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 15px;
        }
    }
    &.is-on-top {
        margin-left: 0.8rem;
        position: relative;
        align-items: center;
        flex-direction: row;
        justify-content: left;
        // height: 25px;
        .status {
            padding: 0;
            width: 6vw;
            height: 4.75vw;
            max-width: 26px;
            max-height: 20px;
            font-size: 0;
            text-align: center;
            span {
                display: inline-block;
                width: 4.75vw;
                height: 4.75vw;
                max-width: 20px;
                max-height: 20px;
                border-radius: 20px;
            }
        }
        .vaccine-required {
            background-image: url(../../images/yellow-stripe.svg);
            background-size: cover;
        }
        .fire-on-job {
            background-image: url(../../images/fire-solid.svg);
            background-size: cover;
        }
    }
}
