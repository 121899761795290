@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.error-container {
    padding: 0 15px 15px 200px;
    @include breakpoint("phone-tablet") {
        padding-left: 15px;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .error-icon {
        font-size: 60px;
    }

    .error-title {
        font-size: 10rem;
        font-weight: 100;
    }
}
