@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.card {
    margin-top: 20px;
    .form-row {
        margin-top: 10px;
    }
    .table-ver-middle td {
        vertical-align: bottom;
        label {
            user-select: none;
        }
    }
    .add-item-container {
        td {
            vertical-align: middle;
        }
        .file-name-label {
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .with-btn {
        position: relative;
        padding-right: 120px;
        .fit-btn {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            right: 15px;
            width: 100px;
            height: 38px;
        }
    }
    .responsive-table {
        @include breakpoint("phone-tablet") {
            display: block;
            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr {
                &:first-child {
                    border-top: none;
                    padding-top: 0;
                }
                border-top: 1px solid #ccc;
                padding: 10px;
            }

            td {
                border-top: none !important;
                position: relative;
                min-height: 30px;
                padding: 2px 0 2px 40% !important;
                &::before {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 10px;
                    white-space: nowrap;
                    width: 40%;
                    font-size: 14px;
                    content: attr(data-th);
                    text-align: left;
                }
                button {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
    .select-required {
        position: relative;
        .required-input {
            opacity: 0;
            height: 0;
            width: 100%;
            position: absolute;
        }
    }
    .ellipsis {
        position: relative;
        &:before {
            content: "&nbsp;";
            visibility: hidden;
            @include breakpoint("phone-tablet") {
                visibility: initial;
            }
        }
        &:hover {
            span.hover-text {
                transition: opacity 0.3s ease-in, transform 0.3s ease-out;
                transform: translateY(25px);
                opacity: 1;
                white-space: pre-wrap;
            }
        }
        span {
            position: absolute;
            left: 0.3rem;
            right: 0.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include breakpoint("phone-tablet") {
                left: 40%;
            }
        }
        span.hover-text {
            pointer-events: none;
            z-index: 3;
            padding: 10px;
            opacity: 0;
            border: 1px solid $company-fire-dark;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transform: translateY(100%);
            overflow: visible;
            white-space: normal;
            height: auto;
            background: white;
        }
    }
}
.form-control-style {
    border: 1px solid $normal-line-color;
    overflow: hidden;
    border-radius: 0.2rem;
}
.text-centre {
    text-align: center;
}
.button-group {
    @include breakpoint("phone-tablet") {
        width: 100%;
        .button-size {
            width: 50% !important;
        }
        a {
            width: 50%;
            .button-size {
                width: 100% !important;
            }
        }
    }
}
.button-size {
    padding: 0.3rem 0.7rem !important;
    font-size: 1rem !important;
}

.btns-in-row {
    @include breakpoint("phone") {
        flex-direction: column;
        button {
            width: 100%;
            margin: 2px 0 !important;
        }
    }
}

.custom-calendar {
    border: none !important;
    width: 100% !important;
}

.consent-container {
    padding-right: 60px !important;
    position: relative;
    .consent-add-btn {
        position: absolute;
        bottom: 3px;
        right: 15px;
        button {
            margin: 0;
        }
    }
}

.time-entry-responsive-table {
    button {
        font-size: 1rem !important;
    }
    @include breakpoint("phone-tablet") {
        display: block;
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            &:first-child {
                border-top: none;
                padding-top: 0;
            }
            border-top: 1px solid #ccc;
            padding: 10px;
        }

        td {
            border-top: none !important;
            position: relative;
            padding: 2px 0 2px 40% !important;
            &::before {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 10px;
                white-space: nowrap;
                width: 40%;
                font-size: 14px;
                content: attr(data-th);
                text-align: left;
            }
            button {
                margin: 0;
                width: 100%;
            }
        }
    }
}

.active-calendar {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        left: calc(50% - 3px);
        top: 5px;
        background: #c46868;
    }
}
