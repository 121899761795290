@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.select-required {
    position: relative;
    .required-input {
        opacity: 0;
        height: 0;
        width: 100%;
        position: absolute;
        z-index: -1;
    }
}
