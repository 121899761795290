@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container {
    padding: 75px 0 20px 170px;
    @include breakpoint("phone-tablet") {
        padding: 75px 0 20px 0;
    }
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: $title-font-size;
    font-weight: 300;
    margin-bottom: 20px;
    color: $company-fire-dark;
    & > span {
        display: inline-block;
    }
    @include breakpoint("phone-tablet") {
        flex-direction: column;
    }
}

.new-button {
    z-index: 3;
    position: fixed;
    top: 70px;
    right: 15px;
    .dropdown-toggle {
        margin: 0;
        padding: 0 !important;
        width: 45px;
        height: 45px;
        border-radius: 22px;
        font-size: 0.875rem !important;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        min-height: 36px;
    }
    .drop-down-item {
        padding: 0;
        .link {
            display: block;
            padding: 0.3rem 1.5rem;
            width: 100%;
            height: 100%;
            color: #333;
            font-size: 0.9rem;
        }
    }
}

.table-ver-middle td,
.table-ver-middle th {
    vertical-align: middle !important;
    label {
        margin-bottom: 0;
        user-select: none;
    }
}

.file-list {
    white-space: normal !important;
    word-break: break-word !important;
    margin-bottom: 2px;
    margin-right: 10px;
    font-size: 0.85rem;
    font-weight: 300;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
}

.card {
    margin-top: 20px;
    button[type="button"] {
        margin: 0;
    }
    &.flat-card {
        margin-top: 0 !important;
        margin-bottom: 20px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        box-shadow: none;
    }
    .new-address-btn {
        margin-left: 5px !important;
        width: 26px;
        height: 26px;
        min-height: 26px;
        color: white;
        background-color: $secondary;
        &:hover {
            background-color: $secondary;
        }
    }
    .card-header {
        display: flex;
        align-items: center;
    }
}

.sticky-header {
    position: sticky;
    top: 55px;
    z-index: 2;
    background-color: #f8f8f8 !important;
}
.body-under-sticky-header {
    position: relative;
    z-index: 1;
}

.service-involve {
    display: inline-block;
    margin-right: 3px;
    padding: 3px 6px;
    font-size: 0.8rem;
    border-radius: 0.2rem;
    background: $normal-line-color;
}

.form-row {
    margin-top: 10px;
}
.add-item-container {
    th {
        vertical-align: middle !important;
        position: relative;
    }
    td {
        vertical-align: middle !important;
        position: relative;
    }
    .file-name-label {
        overflow: hidden;
        white-space: nowrap;
    }
    .preview-text {
        padding-left: 50px;
    }
    .preview-image {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        overflow: hidden;
        border-radius: 0.125rem;
        border: none;
        box-sizing: border-box;
        padding: 0;
        outline: none;
        width: 40px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
        .preview-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
            color: white;
            text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        }
        img {
            object-fit: cover;
            width: 40px;
            height: 40px;
        }
        @include breakpoint("phone-tablet") {
            margin-bottom: 0.25rem;
            position: relative;
            top: inherit;
            transform: inherit;
            left: inherit;
            width: 100%;
            .preview-icon {
                display: none;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.darker-line {
    background-color: rgba(0, 0, 0, 0.05);
}

.night {
    > td {
        color: rgba(255, 255, 255, 0.8);
    }
    background-image: url(../images/dark_back.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.sticky-table-element {
    position: sticky;
    top: 55px;
    background: white;
    z-index: 1;
    &-double {
        top: 111px;
        @include breakpoint("phone-landscape") {
            top: 3px;
        }
    }
}
.consent-container {
    td {
        border-top-width: 2px;
    }
}

// button[type="submit"] {
//     margin: 0;
// }
.summary-sheet-table {
    tr {
        @include breakpoint("phone") {
            th,
            td {
                &:nth-child(1) {
                    display: none;
                }
            }
        }
    }
}

.detail-responsive-table {
    tr.expanded-row-container {
        background-color: #f8f8f8;
        box-shadow: inset 0px 5px 4px -3px rgba(0, 0, 0, 0.2);
    }
    td.expanded-row {
        padding: 20px;
        text-align: left;
        .expanded-wrap {
            margin: 0;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .title {
                width: 20%;
                min-width: 100px;
                color: #333;
                font-weight: 400;
                font-size: 0.9rem;
                margin: 0 10px 0 0;
            }
            .content {
                .file-list {
                    cursor: pointer;
                }
            }
        }
    }
    @include breakpoint("phone-tablet") {
        display: block;
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        &.show-totals {
            thead {
                &:first-child tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }
                tr {
                    position: inherit;
                    top: inherit;
                    left: inherit;
                    background: $company-fire-light-more;
                }
                .invisible {
                    &::before {
                        content: none;
                    }
                    height: 0;
                    min-height: 0;
                    margin: 0;
                    padding: 0 !important;
                }
            }
        }

        tr {
            &:first-child {
                border-top: none;
            }
            border-top: 1px solid #ccc;
            padding: 10px;
        }

        td {
            border-top: none !important;
            position: relative;
            min-height: 30px;
            padding: 2px 0 2px 40% !important;
            &::before {
                position: absolute;
                // top: 50%;
                // transform: translate(0, -50%);
                left: 10px;
                white-space: nowrap;
                width: 40%;
                font-size: 14px;
                content: attr(data-th);
                text-align: left;
            }
            label {
                font-weight: 500;
            }
            button {
                margin: 0;
                width: 100%;
            }
        }
        td.expanded-row {
            padding: 10px !important;
        }
    }
}
.budget-responsive {
    @include breakpoint("phone-tablet") {
        td {
            padding: 2px 0 2px 55% !important;
            &::before {
                width: 55%;
            }
        }
    }
}

.editable-table {
    input:disabled {
        border: none;
        padding: 0;
        background: none;
    }
    select:disabled {
        border: none;
        padding: 0;
        background: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    select:disabled::-ms-expand {
        display: none;
    }
}

.note-text {
    font-weight: 300;
    font-size: 0.9rem;
}

.rates-wrapper {
    .rate-title {
        font-size: 18px;
        margin-top: 20px;
    }
    &:first-child {
        .rate-title {
            font-size: 18px;
            margin-top: 0px;
        }
    }
}

.job-service-rates {
    align-items: center;
    .service-col {
        .service-input {
            margin-bottom: 0.5rem;
        }
    }
}

.role-list {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > li {
        margin: 0.1rem 0.25rem;
        padding: 0.2rem 0.4rem;
        font-size: 0.8rem;
        border-radius: 1rem;
        background: $normal-line-color;
        font-weight: 400;
        color: black;
    }
}

.btns-in-row-reverse {
    @include breakpoint("phone") {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        button {
            width: 100%;
            margin: 2px 0 !important;
        }
    }
}
.table-label {
    margin-top: 0.5rem;
}

.without-btn.with-btn {
    padding-right: 15px !important;
}
.with-btn {
    position: relative;
    padding-right: 120px !important;
    .fit-btn {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        right: 15px;
        width: 100px;
        height: 38px;
    }
}

.approve-card {
    button {
        margin: 0;
    }
}

.work-type {
    font-size: 0.9rem;
    font-weight: 300;
}

.ellipsis {
    position: relative;
    &:before {
        content: "&nbsp;";
        visibility: hidden;
        @include breakpoint("phone-tablet") {
            visibility: initial;
        }
    }
    &:hover {
        span.hover-text {
            transition: opacity 0.2s cubic-bezier(0, 0, 0, 0.2), transform 0.2s cubic-bezier(0, 0, 0, 0.2);
            transform: translateY(25px);
            opacity: 1;
            white-space: pre-wrap;
        }
    }
    span {
        position: absolute;
        left: 0.3rem;
        right: 0.3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint("phone-tablet") {
            left: 40%;
        }
    }
    span.hover-text {
        pointer-events: none;
        text-align: left;
        z-index: 3;
        padding: 10px;
        opacity: 0;
        border: 1px solid $company-fire-dark;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transform-origin: 50% 0;
        transform: translateY(40px);
        overflow: visible;
        white-space: normal;
        height: auto;
        background: white;
    }
}
.total-title {
    display: inline-block;
    margin-right: 10px;
    min-width: 70px;
    @include breakpoint("phone-tablet") {
        display: block;
    }
}

.total-value {
    display: inline-block;
    color: $active-color;
    font-weight: 300;
    font-size: 1.5rem;
}

input[type="date"] {
    word-break: keep-all;
    word-wrap: normal;
    overflow: hidden;
}
.file-drop-in-table {
    border-top: 0;
    padding-top: 0;
}
.file-drop-container {
    padding: 0;
    width: 100%;
    background: transparent;
    border: 2px dashed $normal-line-color;
    border-radius: 0.25rem;
    height: calc(1.5em + 0.75rem + 2px);
    outline: none;
    .file-drop-target {
        padding: 0.3rem;
        height: 100%;
        transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.8);
    }
    .dragging-over-target {
        background: rgba(0, 0, 0, 0.15);
    }
}
.file-upload-body {
    .file-drop-container {
        &.error {
            border-color: $red;
        }
        height: auto;
        .file-drop-target {
            padding: 40px 0.3rem;
        }
    }
}

.pill-position {
    position: absolute;
    top: -17px;
    right: -7px;
    z-index: 1;
    pointer-events: none;
}

.btns-in-row {
    @include breakpoint("phone") {
        button {
            width: 100%;
            margin: 2px 0 !important;
        }
    }
}

.anchor-like {
    display: inline-block;
    padding: 3px 0;
    font-style: italic;
}

.btns-in-row-reverse {
    @include breakpoint("phone") {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        button {
            width: 100%;
            margin: 2px 0 !important;
        }
    }
}

.height-transition {
    transition: height 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.text-centre {
    text-align: center;
}
.button-size {
    padding: 0.3rem 0.7rem !important;
    font-size: 1rem !important;
}
.right-position-button {
    position: absolute !important;
    top: 50%;
    transform: translate(0, -50%);
    right: 0.3rem;
    @include breakpoint("phone-tablet") {
        position: relative !important;
        transform: initial;
        top: initial;
        right: initial;
        margin-top: 0.25rem !important;
    }
}
.qs-checklist {
    margin-bottom: 0;
}

.editable-text {
    border: none;
    background: none !important;
    font-size: 0.9rem;
    padding: 0;
    font-weight: 300;
    height: auto;
    -webkit-appearance: none;
}

.key-info {
    position: absolute;
    top: 13px;
    left: 220px;
    width: calc(100% - 235px);
    padding-left: 10px;
    transform: translate(0, -50%);
    border-left: 1px solid rgb(218, 119, 43);
    font-size: 0.9rem;
    color: darkgray;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    @include breakpoint("phone-tablet") {
        position: relative;
        left: 18px;
        top: 0;
        width: calc(100% - 18px);
        margin-top: 5px;
        transform: translate(0, 0);
    }
}
.key-info-show {
    opacity: 1;
}

.key-info-hide {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 0;
    overflow: hidden;
}

.key-info-under-review {
    left: 270px;
    @include breakpoint("phone-tablet") {
        left: 18px;
    }
}

.text-pre-wrap {
    white-space: pre-wrap;
    margin-bottom: 0;
}

.invisible {
    visibility: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.button-group {
    button {
        margin: 0 !important;
    }
    @include breakpoint("phone-tablet") {
        width: 100%;
        .button-size {
            width: 50% !important;
        }
        a {
            width: 50%;
            .button-size {
                width: 100% !important;
            }
        }
    }
}

.tool-tip {
    z-index: 999 !important;
}

.role-tool-tip-popper {
    z-index: 999 !important;
}

.role-tool-tip {
    border-radius: 0.5rem !important;
    background-color: white !important;
    border: 1px solid $normal-line-color;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.12);
}

.capsule-list {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    & > li {
        display: flex;
        align-items: center;
        margin: 0.1rem 0.25rem;
        padding: 0.2rem 0.4rem;
        font-size: 0.8rem;
        border-radius: 1rem;
        background: $normal-line-color;
        color: black;
        font-weight: 400;
        & > span {
            margin: -0.2rem -0.4rem;
            margin-left: 0.3rem;
            padding: 0.2rem 0.4rem;
            border-radius: 1rem;
            min-width: 1.6rem;
            text-align: center;
            background: $company-fire-dark;
            color: white;
            font-size: 0.8rem;
            font-weight: 700;
        }
    }
}

.parent-breadcrums {
    margin-left: 33px;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
    // border: 1px solid $company-fire-dark;
    // border-radius: 3px;
    // overflow: hidden;
    li {
        margin-right: 22px;
        margin-bottom: 5px;
        position: relative;
        a {
            display: inline-block;
            padding: 0 5px;
            font-weight: 400;
            color: $company-fire-dark;
            text-decoration: underline !important;
        }
        &::after {
            content: " ";
            position: absolute;
            top: 6px;
            right: -13px;
            width: 11px;
            height: 11px;
            border: 1px solid $company-fire-dark;
            border-left: 0;
            border-bottom: 0;
            transform: rotate(45deg);
        }
        &:last-child {
            margin-right: 0px;
            &::after {
                content: "";
                border: none;
            }
        }
    }
}

.rotate30-deg {
    transform: rotate(30deg);
}

.pre-wrap {
    white-space: pre-wrap;
}

.pinned {
    font-size: 0.8rem;
    color: purple;
    margin-left: 5px;
}
.ellipsis-text {
    display: inline-block;
    vertical-align: top;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contact-pill {
    display: inline-block;
    background: $normal-line-color;
    color: white;
    border-radius: 0.2rem;
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0.1rem;
    &.type {
        &-1 {
            background: $primary;
        }
        &-2 {
            background: $purple;
        }
        &-3 {
            background: $orange;
        }
        &-4 {
            background: $green;
        }
        &-5 {
            background: $cyan;
        }
        &-6 {
            background: $indigo;
        }
        &-7 {
            background: $pink;
        }
    }
}

.total-section-area {
    border-top: 1px solid $active-color;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-weight: 700;
    dt {
        padding: 0.5rem;
        font-weight: bold;
        flex: 2;
    }
    dd {
        margin: 0;
        padding: 0.5rem;
        flex: 1;
    }
}
.vaccine-required {
    display: inline-block;
    border: 2px solid #333;
    border-radius: 100%;
    padding: 8px;
    line-height: 0;
    font-size: 1.2rem;
    background-image: url(../images/yellow-stripe.svg);
    background-size: cover;
}

.new-button-in-view {
    background-color: $secondary !important;
    color: white !important;
}
