@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container {
    width: 100%;
    border: 1px solid $normal-line-color;
    :global {
        .react-calendar__navigation {
            height: 60px;
            margin-bottom: 0;
        }
        .react-calendar__viewContainer {
            border-top: 1px solid $normal-line-color;
        }
        .react-calendar__tile--now {
            background-color: rgb(233, 233, 233);
            color: black;
        }
        .react-calendar__tile--now.react-calendar__tile--active {
            background-color: rgb(233, 233, 233);
            color: black;
        }
        .react-calendar__tile--active {
            background-color: white;
            color: black;
        }
        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            background-color: white;
        }
        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
            background-color: white;
        }
        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus {
            background-color: rgb(233, 233, 233);
            color: black;
        }
    }
}
.tile {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $normal-line-color !important;
    border-left: 1px solid $normal-line-color !important;
    &:nth-child(7n + 1) {
        border-left: none !important;
    }
    min-height: 110px;
    text-align: right;
    vertical-align: top;
    cursor: default !important;
}
.type-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
