@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.download-report-container {
    position: relative;
    z-index: 1000;
    .report-button-root {
        position: fixed;
        bottom: -5px;
        left: 30px;
        padding: 5px 15px 10px;
        background-color: white;
        color: $purple;
        transition: transform 0.2s cubic-bezier(0, 0, 0, 0.2);
        transform: translateY(0);
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        font-size: 0.9rem;
        font-weight: 400;
        border: none;
        border: 1px solid $purple;
        border-bottom: none;
        @include breakpoint("phone") {
            left: 15px;
        }
        &:hover {
            transform: translateY(-5px);
        }
    }

    .drawer-root {
        padding: 20px 0 10px;
        overflow-y: initial !important;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        &:focus {
            outline: none;
        }
    }
}

@media print {
    .download-report-container {
        display: none;
    }
}
