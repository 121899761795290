@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container {
    padding-top: 75px;
    padding-left: 170px;
    @include breakpoint("phone-tablet") {
        padding-left: 0px;
    }
    .title-wrap {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        @include breakpoint("phone-tablet") {
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
        }
        .task-title {
            font-size: 1.5rem;
            font-weight: 300;
            color: #333;
            display: flex;
            align-items: center;
            .button-size {
                margin-right: 0.3rem !important;
            }
            & > i {
                color: $company-fire-dark;
            }
        }
        .switch-wrap {
            display: flex;
            margin-left: 15px;
            margin-bottom: 0.5rem;
            @include breakpoint("phone-tablet") {
                margin-left: 0;
            }
            .list-switch {
                margin-right: 15px;
                label {
                    font-size: 12px;
                    padding: 3px 0;
                }
            }
        }
    }

    .pill-position {
        position: absolute;
        top: -17px;
        right: -7px;
        z-index: 1;
        pointer-events: none;
    }

    .button-size {
        margin: 0 !important;
        padding: 0.3rem 0.6rem !important;
        min-width: 38px;
        font-size: 1rem !important;
    }

    .button-size.no-round {
        border-radius: 0;
    }

    .button-size.new-button {
        @include breakpoint("phone") {
            display: none;
        }
        font-size: 0.6rem !important;
        padding: 0.64rem 0.5rem !important;
    }

    .linings-on-job {
        background-color: #7fcb2b !important;
    }

    .data-table {
        tbody {
            tr {
                th {
                    vertical-align: middle;
                    font-size: $normal-font-size;
                    line-height: 1.1rem;
                }
                td {
                    vertical-align: middle;
                    font-size: $normal-font-size;
                    line-height: 1.1rem;
                    .button-size {
                        padding: 0.3rem 0.7rem;
                        font-size: 1rem;
                    }
                }
                .no-data {
                    padding: 40px 0;
                    text-align: center;
                }
            }
        }
        tbody + thead {
            display: none;
        }
        .under-review {
            background-color: #c46868;
            color: white;
        }
    }

    .jobs-data-table {
        thead {
            tr {
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td:nth-child(7) {
                    width: 70px;
                }
                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                        text-align: center;
                    }
                }

                @include breakpoint("tablet") {
                    td {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .diary-data-table {
        thead {
            tr {
                @include breakpoint("desktop-xl") {
                    th {
                        &:nth-child(3) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(3),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td:nth-child(8) {
                    width: 70px;
                }
                @include breakpoint("desktop-xl") {
                    td {
                        &:nth-child(3) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("tablet") {
                    td {
                        &:nth-child(3),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
    }

    .preqa-data-table {
        thead {
            tr {
                th:nth-child(7) {
                    width: 70px;
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(2),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("tablet") {
                    td {
                        &:nth-child(2),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
    }

    .qa-data-table {
        thead {
            tr {
                th:nth-child(8) {
                    width: 70px;
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(2),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("tablet") {
                    td {
                        &:nth-child(2),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
    }

    .tasks-data-table {
        thead {
            tr {
                th {
                    &:nth-child(9) {
                        width: 70px;
                    }
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                        text-align: center;
                    }
                }

                @include breakpoint("tablet") {
                    td {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .tasks-request-data-table {
        thead {
            tr {
                th {
                    &:nth-child(8) {
                        width: 70px;
                    }
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }

                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                        text-align: center;
                    }
                }

                @include breakpoint("tablet") {
                    td {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .sheet-data-table {
        thead {
            tr {
                th {
                    &:nth-child(6) {
                        width: 70px;
                    }
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(2),
                        &:nth-child(5) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("tablet") {
                    td {
                        &:nth-child(2),
                        &:nth-child(5) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
    }

    .jobsheet-data-table {
        thead {
            tr {
                th {
                    &:nth-child(9) {
                        width: 70px;
                    }
                }

                @include breakpoint("desktop-xl") {
                    th {
                        &:nth-child(4) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("desktop-xl") {
                    td {
                        &:nth-child(4) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("tablet") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
    }

    .inspection-data-table {
        thead {
            tr {
                @include breakpoint("tablet") {
                    th {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("phone") {
                    th {
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
        tbody {
            tr {
                @include breakpoint("tablet") {
                    td {
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
                @include breakpoint("phone") {
                    td {
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(7),
                        &:nth-child(8) {
                            display: none;
                        }
                        text-align: center;
                    }
                }
            }
        }
    }
    @media print {
        div:global(.dataTables_length),
        div:global(.dataTables_filter),
        div:global(.dataTables_info) {
            display: none;
        }
        .new-button,
        .list-switch {
            display: none;
        }
        .jobs-data-table {
            thead {
                tr {
                    th {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: table-cell;
                        }
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: table-cell;
                        }
                        &:nth-child(6) {
                            display: none;
                        }
                    }
                }
            }
        }

        .tasks-data-table {
            thead {
                tr {
                    th {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: table-cell;
                        }
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: table-cell;
                        }
                        &:nth-child(6),
                        &:nth-child(7) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
