@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.header-container {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    .header.entered {
        height: 55px;
        @include breakpoint("phone-tablet") {
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            .apps-menu {
                pointer-events: auto;
                transform: translateY(-20px);
                opacity: 1;
            }
        }
        .indicators-container {
            display: none;
        }
    }
    .header {
        box-sizing: border-box;
        color: #333;
        display: flex;
        flex-direction: row-reverse;
        font-size: 16px;
        font-weight: 100;
        height: 55px;
        justify-content: space-between;
        letter-spacing: 2px;
        overflow: hidden;
        position: relative;
        text-align: right;
        transition: height 0.5s cubic-bezier(0.57, 0.12, 0.45, 0.92);
        width: 100%;
        z-index: 3;
        @include breakpoint("phone-tablet") {
            flex-direction: row;
        }
        .page-title {
            margin: 0;
            padding: 15px 10px;
            display: flex;
            align-items: center;
            height: 55px;
            font-size: 20px;
            letter-spacing: 0.4px;
            line-height: 1;
            .desktop {
                display: inline-block;
            }
            .mobile {
                display: none;
            }
            .sub-title {
                margin-left: 5px;
                font-size: 14px;
                color: rgba(10, 10, 10, 0.7);
                display: inline-block;
            }
            @include breakpoint("phone-tablet") {
                .desktop {
                    display: none;
                }
                .mobile {
                    display: inline-block;
                    text-align: start;
                }
            }
        }
        .portal {
            font-weight: 700;
        }

        .version-number {
            font-size: 0.9rem;
            font-weight: lighter;
            @include breakpoint("phone-tablet") {
                font-size: 0.8rem;
            }
        }

        .indicators-container {
            display: flex;
            align-items: center;
            height: 55px;
            flex: 1 1;
            padding: 15px 30px 15px 10px;
        }
        .indicators {
            display: flex;
            align-items: center;
            text-align: left;
            h2 {
                margin: 0;
                font-size: 0.9rem;
                letter-spacing: 0;
                @include breakpoint("phone-tablet") {
                    font-size: 0.8rem;
                }
            }
        }

        .top-menu {
            position: absolute;
            right: 10px;
            display: flex;
            margin: 0;
            text-align: left;
            @include breakpoint("phone-tablet") {
                flex-direction: column;
                display: none;
                left: 50%;
                top: 50%;
                -webkit-transform: -webkit-translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
            }
            li {
                margin-left: 7px;
                a {
                    color: #333;
                    font-weight: 300;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    font-family: $font-family;
                }
                a.active {
                    color: $active-color;
                    font-weight: 500;
                }
            }
        }
        .ham-menu.opened {
            button {
                span {
                    &:nth-child(1) {
                        -webkit-transform: translateY(10px) rotate(-45deg);
                        transform: translateY(10px) rotate(-45deg);
                    }
                    &:nth-child(2) {
                        -webkit-transform: translate(100px, 0);
                        transform: translate(100px, 0);
                    }
                    &:nth-child(3) {
                        -webkit-transform: translateY(-8px) rotate(45deg);
                        transform: translateY(-8px) rotate(45deg);
                    }
                }
            }
        }
        .ham-menu {
            margin-left: 5px;
            padding: 15px 10px;
            height: 55px;
            display: none;
            overflow: hidden;
            button {
                outline: none;
                padding: 0;
                position: relative;
                width: 30px;
                height: 20px;
                background: none;
                border: none;
                display: block;
                span {
                    -webkit-transform-origin: center;
                    transform-origin: center;
                    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.67, 0.34, 0.4, 0.93);
                    transition: transform 0.4s cubic-bezier(0.67, 0.34, 0.4, 0.93);
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: #333;
                    position: absolute;
                    &:nth-child(1) {
                        -webkit-transform: rotate(0deg) translate(0);
                        transform: rotate(0deg) translate(0);
                        top: 0%;
                    }
                    &:nth-child(2) {
                        -webkit-transform: translate(0);
                        transform: translate(0);
                        top: 46%;
                    }
                    &:nth-child(3) {
                        -webkit-transform: rotate(0deg) translate(0);
                        transform: rotate(0deg) translate(0);
                        bottom: 0%;
                    }
                }
            }
            @include breakpoint("phone-tablet") {
                display: block;
            }
        }
        .apps-menu {
            padding: 15px 10px;
            @include breakpoint("phone-tablet") {
                -webkit-transition: all 0.5s cubic-bezier(0.57, 0.12, 0.45, 0.92);
                transition: all 0.5s cubic-bezier(0.57, 0.12, 0.45, 0.92);
                position: absolute;
                opacity: 0;
                padding: 0 10px;
                top: 34px;
                right: 35px;
                pointer-events: none;
            }
            button {
                img {
                    width: 20px;
                    height: 20px;
                }
                font-size: 0.9rem;
                background: none;
                border: none;
                outline: none;
                color: #333;
            }
        }
        .is-open {
            @include breakpoint("phone-tablet") {
                pointer-events: auto;
                transform: translateY(-20px);
                opacity: 1;
            }
        }
    }

    .header-linings {
        background-color: $company-fire-dark;
    }
    .nav-container.main-nav-show {
        @include breakpoint("phone-tablet") {
            z-index: 3;
            -webkit-transition: -webkit-transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            top: 50%;
            opacity: 1;
            .nav-list {
                li {
                    pointer-events: initial;
                }
            }
        }
    }
    .nav-container {
        padding-top: 80px;
        position: fixed;
        top: 0;
        left: 0;
        width: 170px;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        background-color: $company-fire-light;
        z-index: 1;
        display: block;
        @include breakpoint("phone-tablet") {
            padding: 0;
            width: 100%;
            position: absolute;
            top: -9999px;
            left: 50%;
            -webkit-transform: -webkit-translate(-50%, -70%);
            transform: translate(-50%, -70%);
            opacity: 0;
            background-color: transparent;
            text-align: left;
            min-height: 0;
            z-index: 2;
        }
        .nav-title {
            font-size: 20px;
            padding-left: 15px;
            font-weight: 300;
            @include breakpoint("phone-tablet") {
                display: none;
            }
            color: #333;
        }
        .nav-list {
            li {
                position: relative;
                display: flex;
                @include breakpoint("phone-tablet") {
                    justify-content: center;
                    pointer-events: none;
                }
                i {
                    font-size: 1rem;
                    min-width: 15px;
                    text-align: center;
                    display: inline-block;
                    @include breakpoint("phone-tablet") {
                        display: none;
                    }
                }
                a {
                    color: #333;
                    display: block;
                    padding: 10px 0 10px 15px;
                    &:hover {
                        text-decoration: none;
                    }
                    @include breakpoint("phone-tablet") {
                        padding: 0;
                        color: #333;
                        font-size: 2rem;
                        font-weight: 300;
                    }
                    span {
                        margin-left: 10px;
                        @include breakpoint("phone-tablet") {
                            margin-left: 0;
                        }
                    }
                }
                a.active {
                    span {
                        font-weight: 500;
                        color: $active-color;
                        @include breakpoint("phone-tablet") {
                            font-weight: 400;
                        }
                    }
                }
                .new {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 11px 10px;
                    font-size: 0;
                    @include breakpoint("phone-tablet") {
                        position: relative;
                        top: initial;
                        right: initial;
                    }
                    i {
                        font-size: 1.3rem;
                        color: $active-color;
                        @include breakpoint("phone-tablet") {
                            display: block;
                            font-size: 2rem;
                        }
                    }
                }
                button {
                    border: none;
                    background: none;
                    padding: 10px 0 10px 15px;
                    margin: 0;
                    color: #333;
                    font-weight: 300;
                    outline: none;
                    @include breakpoint("phone-tablet") {
                        padding: 0;
                        color: #333;
                        font-size: 2rem;
                        font-weight: 300;
                    }
                    span {
                        margin-left: 10px;
                        @include breakpoint("phone-tablet") {
                            margin-left: 0;
                        }
                    }
                }
            }
            .sub-menu {
                padding-left: 8px;
                background-color: rgba(255, 255, 255, 0.2);
                @include breakpoint("phone-tablet") {
                    padding-left: 0;
                    background-color: transparent;
                    a {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}
