@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.container {
    padding: 70px 15px 15px;
}

.dash-container {
    padding-top: 55px;
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.menu-container {
    position: fixed;
    padding: 15px;
    width: 200px;
    background: $dashboard-content-background;
    height: calc(100vh - 55px);
    @include breakpoint("phone-tablet") {
        padding: 15px 5px;
        width: 50px;
    }
    .menu {
        li {
            margin-bottom: 10px;
            a {
                color: $dark;
                display: flex;
                align-items: center;
                border-radius: 15px;
                &:hover {
                    color: $blue;
                    background-color: rgba($blue, 0.1);
                }
                i {
                    margin-right: 10px;
                    padding: 6px 0;
                    width: 40px;
                    height: 30px;
                    text-align: center;
                    &.jobs {
                        color: $job-menu-background;
                    }
                    &.tasks {
                        color: $task-menu-background;
                    }
                    &.budget {
                        color: $budget-menu-background;
                    }
                    &.operation {
                        color: $operation-menu-background;
                    }
                    &.crm {
                        color: $crm-menu-background;
                    }
                    &.material {
                        color: $material-menu-background;
                    }
                    &.stock {
                        color: $stock-menu-background;
                    }
                    &.accountpayable {
                        color: $accountpayable-menu-background;
                    }
                    &.ingestion {
                        color: $ingestion-menu-background;
                    }
                    &.hr {
                        color: $hr-menu-background;
                    }
                }
            }
            @include breakpoint("phone-tablet") {
                a {
                    i {
                        margin-right: 0;
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

.content-container {
    width: 100%;
    padding: 20px;
    // @include breakpoint("phone-tablet") {
    //     padding-left: 70px;
    // }
}
