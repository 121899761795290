@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.attendee-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    li {
        box-sizing: border-box;
        position: relative;
        padding: 0.25rem 2rem 0.25rem 0.5rem;
        margin-right: 0.3rem;
        margin-bottom: 0.3rem;
        background: $normal-line-color;
        border-radius: 0.2rem;
        font-size: 0.8rem;
        overflow: hidden;
        &.existed-attendee {
            opacity: 0.65;
        }
        &.disabled-attendee {
            padding: 0.25rem 0.5rem;
        }
        .attendee-name-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            input[type="text"] {
                &:focus {
                    outline-color: $active-color;
                }
                margin-left: 0.5rem;
                max-width: 150px;
                border: none;
                border-radius: 0.2rem;
                background: rgba(255, 255, 255, 0.5);
            }
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.25rem 0.4rem;
            color: white;
            height: 100%;
            background: $danger;
            border: none;
            outline: none;
        }
    }
}
