@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.basic-back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.linings-back {
    background-color: $company-fire-dark;
}

.login-container.entered {
    transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
    opacity: 1;
    transform: translate(0, 0);
}
.login-container {
    position: relative;
    padding: 50px 20px;
    max-width: 400px;
    max-height: 500px;
    width: 100%;
    background: white;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
    transform: translate(0, 20%);
    opacity: 0;
    .title {
        display: inline-block;
        position: relative;
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: $company-fire-dark;
        &::after {
            content: "";
            position: absolute;
            top: 110%;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background: $company-fire-normal;
        }
        p {
            margin: 10px 0 0 0;
        }
        svg {
            width: 40px;
            fill: $company-fire-dark;
        }
    }
    .welcome {
        color: #333;
    }

    .button-container {
        text-align: center;
        .logo {
            margin-right: 8px;
            width: 15px;
            svg {
                width: 19px;
                vertical-align: text-bottom;
                fill: white;
            }
        }
    }
}
