@import "/Users/louis/Desktop/works/FirePortal-SPA/src/config/_variables.scss";
.stage-list {
    margin: 5px 0px 15px;
    li {
        margin: 5px 0;
        padding: 15px;
        gap: 10px;
        width: 100%;
        background: white;
        padding-right: 50px;
        border: 1px solid $normal-line-color;
        border-radius: 3px;
        box-shadow: 1px 1px 4px rgba($normal-line-color, 0.5);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        p {
            margin-bottom: 0;
        }
        .address {
            color: gray;
            font-size: 11px;
        }
    }
}

.responsive-table {
    @include breakpoint("phone-tablet") {
        tr {
            padding: 0;
        }
        td {
            width: 100%;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            text-align: left;
            &::before {
                top: 50%;
                transform: translate(0, -50%);
            }
            button {
                width: auto;
            }
        }
        .no-data {
            padding: 0 !important;
            min-height: 0px;
            height: 0px;
            border: none !important;
            font-size: 0;
            line-height: 0;
        }
    }
}

.note-list {
    width: 100%;
    margin-right: -10px;
    li {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        span {
            line-height: 1;
            font-size: 0.8rem;
            font-weight: bold;
        }
        p {
            margin: 0;
            padding-right: 60px;
            white-space: pre-wrap;
        }
        padding: 5px;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
