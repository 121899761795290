// FONT
$small-font-size: 12px;
$normal-font-size: 14px;
$large-font-size: 16px;
$title-font-size: 30px;
$font-family: "Roboto", sans-serif;

// COLOR
$active-color: #a74c0b;
$company-fire-dark: #f47920;
$company-fire-normal: #ffd7bb;
$company-fire-light: #ffd7bb;
$dashboard-content-background: #f0f7e8;
$company-fire-light-more: #eed3c0;
$content-background: #fcfcfce0;
$normal-line-color: #ced4da;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #007bff;
$secondary: #a6c;
$success: #00c851;
$info: #17a2b8;
$warning: #ffc107;
$danger: #ff3547;
$light: #f8f9fa;
$dark: #343a40;

$job-menu-background: #4285f4;
$task-menu-background: #5e49de;
$budget-menu-background: #4bb3f1;
$operation-menu-background: #da6bde;
$crm-menu-background: #b399e2;
$material-menu-background: #f7779e;
$stock-menu-background: #f57f17;
$accountpayable-menu-background: #82b904;
$ingestion-menu-background: #6c757d;
$hr-menu-background: $success;

// SIZE

$tablet-width: 768px;
$desktop-width: 875px;
$xl-desktop-width: 1351px;
$phone-width: 320px;
$iphone6-width: 375px;
$iphone6plus-width: 450px;

@mixin breakpoint($breakpoint) {
    @if $breakpoint == "tablet" {
        @media (min-width: #{$iphone6plus-width}) and (max-width: #{$desktop-width}) {
            @content;
        }
    } @else if $breakpoint == "tablet-landscape" {
        @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) and (orientation: landscape) {
            @content;
        }
    } @else if $breakpoint == "table-portrait" {
        @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) and (orientation: portrait) {
            @content;
        }
    } @else if $breakpoint == "phone" {
        @media (max-width: #{$iphone6plus-width}) {
            @content;
        }
    } @else if $breakpoint == "phone-landscape" {
        @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width}) and (orientation: landscape) {
            @content;
        }
    } @else if $breakpoint == "desktop-xl" {
        @media (max-width: #{$xl-desktop-width}) {
            @content;
        }
    } @else if $breakpoint == "phone-tablet" {
        @media (max-width: #{$desktop-width}) {
            @content;
        }
    }
}
